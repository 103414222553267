import React, { useEffect, useState, useRef } from "react";

export function OurFocusAnimation({portfolioRef}) {

    const animation1 = useRef();
    const animation2 = useRef();
    const [ rotation1, setRotation1 ] = useState(120);
    const [ rotation2, setRotation2 ] = useState(240);
    //const [ windowHeight, setWindowHeight ]
    //const [ bottom, setBottom ] = useState(60);
    const [ scale, setScale ] = useState(1);
    const [ scaleLarge, setScaleLarge ] = useState(1);
    const [ opacity, setOpacity ] = useState(0);

    const handleScroll = () => {
        const footerTop = window.document.querySelector("footer").getBoundingClientRect().top;
        let portfolioTop = 0;
        if (portfolioRef && portfolioRef.current) {
            portfolioTop = portfolioRef.current.getBoundingClientRect().top;
        }
        const windowHeight = window.screen.height;
        const windowWidth = window.screen.width;
        const pageHeight = window.document.body.scrollHeight;
        const pageMiddle = pageHeight/1.5;
        let scroll = window.pageYOffset;
        let curOpacity = 1;
        let additionalBot = 0;

        let animationScale = Math.abs(2 - (pageMiddle/1.1 - Math.abs(pageMiddle - scroll))/1500);
        if (animationScale > 1) {
            animationScale = 1;
        } else if (animationScale < 0.4) {
            animationScale = 0.4;
        }
        const largeBreakPoint = pageMiddle/5;
        // let animationLargeScale = 1 + scroll/3000;
        let animationLargeScale = Math.abs(1 + (largeBreakPoint - Math.abs(largeBreakPoint - scroll))/2000);
        if (animationLargeScale < 0.7) {
            animationLargeScale = 0.7;
        }
        setScale(animationScale);
        setScaleLarge(animationLargeScale);

        if (portfolioTop - windowHeight < 0) {
            curOpacity = 1 + (portfolioTop - windowHeight)/500;
            additionalBot = (portfolioTop - windowHeight)/20;
            scroll += portfolioTop - windowHeight;
        } else if (portfolioTop - windowHeight < -200) {
            scroll += portfolioTop - windowHeight - 200/windowHeight;
        }

        let rotValue = 180 + scroll/15 - additionalBot;
        //let curScale =
        // let botValue = 60 - scroll/6 + additionalBot;
        setRotation1(rotValue);
        setRotation2(-rotValue);
        //setBottom(botValue);
        setOpacity(curOpacity);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setRotation1(180);
            setRotation2(180);
            setOpacity(1);
        }, 100);
        setTimeout(() => {
            animation1.current.classList.add("_active");
            animation2.current.classList.add("_active");
        }, 200);
    }, []);

    return (
        <>
            <img
                className="animated-1"
                src="img/animations/our-focus-1.png"
                id="img"
                ref={animation1}
                style={
                    {
                        "transform":"rotate(" + rotation1 + "deg) scale(" + scaleLarge + ")",
                        "opacity": opacity,
                    }
                }
            />
            <div
                className="animated-2"
                style={
                    {
                        "opacity": opacity,
                    }
                }
            >
                <img
                    src="img/animations/our-focus-2.png"
                    className="img"
                    ref={animation2}
                    style={
                        {
                            "transform":"rotate(" + rotation2 + "deg) scale(" + scale + ")",
                            "opacity": opacity/2,
                        }
                    }
                />
            </div>
        </>
    );
}
