import React, { useEffect, useState, useRef } from 'react';

export function WhoWeAreAnimation() {

    const animation1 = useRef();
    const animation2 = useRef();
    const [ rotation1, setRotation1 ] = useState(100);
    const [ rotation2, setRotation2 ] = useState(-190);
    //const [ windowHeight, setWindowHeight ]
    //const [ bottom, setBottom ] = useState(60);
    const [ scale, setScale ] = useState(1);
    const [ opacity, setOpacity ] = useState(0);

    const handleScroll = () => {
        const footerTop = window.document.querySelector("footer").getBoundingClientRect().top;
        const windowHeight = window.screen.height;
        const pageHeight = window.document.body.scrollHeight;
        const pageMiddle = pageHeight/1.5;
        let scroll = window.pageYOffset;
        let curOpacity = 1;
        let additionalBot = 0;

        let animationScale = Math.abs(2 - (pageMiddle - Math.abs(pageMiddle - scroll))/1500);
        if (animationScale > 1) {
            animationScale = 1;
        }
        // setScale(animationScale);

        if (footerTop - windowHeight < 0) {
            curOpacity = 1 + (footerTop - windowHeight)/500;
            additionalBot = (footerTop - windowHeight)/20;
            scroll += footerTop - windowHeight;
        } else if (footerTop - windowHeight < -200) {
            scroll += footerTop - windowHeight - 200/windowHeight;
        }

        let rotValue1 = 120 + scroll/90 - additionalBot;
        let rotValue2 = 210 + scroll/90 - additionalBot;
        //let curScale =
        // let botValue = 60 - scroll/6 + additionalBot;
        setRotation1(rotValue1);
        setRotation2(-rotValue2);
        //setBottom(botValue);
        setOpacity(curOpacity);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setRotation1(120);
            setRotation2(-210);
            setOpacity(1);
        }, 100);
        setTimeout(() => {
            animation1.current.classList.add("_active");
            animation2.current.classList.add("_active");
        }, 200);
    }, []);

    return (
        <>
            <img
                className="animated-1"
                src="img/animations/who_we_are_01.png"
                ref={animation1}
                style={
                    {
                        "transform": "translate(-25%, -50%) rotate(" + rotation1 + "deg)",
                        "opacity": opacity,
                        "top": 6*(rotation1 - 120) + "px"
                    }
                }
            />
            <img
                className="animated-2"
                src="img/animations/who_we_are_02.png"
                ref={animation2}
                style={
                    {
                        "transform":"translate(-20%, -70%) rotate(" + rotation2 + "deg) scale(" + scale + ")",
                        "opacity": opacity,
                        "top": 8*(rotation1 - 120) + "px"
                    }
                }
            />
        </>
    );
}
