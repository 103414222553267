import React, { useEffect, useState, useRef } from "react";

export function MainWhoWeAreAnimation({ staffRef }) {
    const animation = useRef();
    const [rotation, setRotation] = useState(0);
    //const [ bottom, setBottom ] = useState(60);
    const [opacity, setOpacity] = useState(1);
    const [top, setTop] = useState(0);
    const [transTop, setTransTop] = useState("-120%");

    const handleScroll = () => {
        if (staffRef && staffRef.current) {
            const footerTop = window.document
                .querySelector("footer")
                .getBoundingClientRect().top;
            const windowHeight = window.screen.height;
            const windowWidth = window.screen.width;
            const staffTop = staffRef.current.getBoundingClientRect().top;
            let scroll = window.pageYOffset;
            let curOpacity = opacity;
            let additionalBot = 0;
            if (footerTop - windowHeight < 0) {
                curOpacity = 1 + (footerTop - windowHeight) / 500;
                additionalBot = (footerTop - windowHeight) / 40;
                scroll += footerTop - windowHeight;
            } else if (footerTop - windowHeight < -200) {
                scroll += footerTop - windowHeight - 200 / windowHeight;
            }

            let rotValue = scroll / 40 - additionalBot;
            //let botValue = 60 - scroll/6 + additionalBot;
            setRotation(rotValue);
            //setBottom(botValue);
            setOpacity(curOpacity);
            if (staffTop - windowHeight < 0) {
                setTop(windowHeight);
            }
            if (windowWidth < 1440 && windowWidth > 767) {
                setTransTop("-105%");
            } else {
                setTransTop("-120%");
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <img
            className="animated main-staff-animation"
            src="img/animations/careers-1.png"
            id="main-staff-animation"
            ref={animation}
            style={
                {
                    "transform":"translate(-90%," + transTop + ") rotate(" + rotation + "deg)",
                    "top": top + "px",
                    "opacity": opacity,
                }
            }
        />
    );
}
