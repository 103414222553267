import React, { useEffect, useContext, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { domain, Context } from '../../config';
import {
    MainAnimation,
    MainWhoWeAreAnimation,
} from '../../components/animations';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getHome } from '../../store/ducks/home/actions';
import { LayoutHomeState } from '../../store/ducks/home/types';
import { State } from '../../types';

const HomeEn = () => {
    const { siteDomain, locale } = useContext(Context);
    const staffRef = useRef();
    const buttonRef = useRef();
    const dispatch = useDispatch();
    const home: LayoutHomeState = useSelector(
        (state: State) => state.home.data
    );
    const status: string = useSelector((state: State) => state.home.status);

    useEffect(() => {
        if (status === 'IDLE') {
            dispatch(getHome(locale));
        }
    }, []);

    // animations
    const titleRef = useRef();
    const [titleArr, setTitleArr] = useState([]);
    const [descArr, setDescArr] = useState([]);

    useEffect(() => {
        const title = generateSpans([home.subTitle]);
        const description = generateSpans([home.description]);
        setTitleArr(title);
        setDescArr(description);
    }, [home]);

    const generateSpans = texts => {
        let spans = [];
        if (texts && texts.length > 0) {
            texts.forEach(item => {
                spans = [...spans, ...item.split(' ')];
            });
        }
        return spans;
    };

    const handleScroll = () => {
        let scroll = window.pageYOffset;
        const windowHeight = window.screen.height / 1.5;
        //const windowHeightTop = window.screen.height/2.8;
        //let scrollSpansTop = window.document.querySelectorAll(".scroll-span-top");
        let scrollSpans = window.document.querySelectorAll('.scroll-span');
        scrollSpans.forEach(item => {
            if (scroll + windowHeight >= item.offsetTop) {
                item.classList.add('_animated');
            } else {
                item.classList.remove('_animated');
            }
        });
        /*scrollSpansTop.forEach(item => {
            if (scroll + windowHeightTop >= item.offsetTop) {
                item.classList.add("_animated");
            }
        });*/
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        //const titleRef;
        if (titleArr && titleArr.length > 0) {
            const allSpans = titleRef.current.querySelectorAll(
                '.scroll-span-top'
            );
            let spanOfftop = 0;
            let lineIndex = 0;
            let linesArr = [];
            allSpans.forEach(item => {
                if (spanOfftop < item.offsetTop) {
                    spanOfftop = item.offsetTop;
                    lineIndex++;
                    linesArr = [...linesArr, lineIndex];
                }
                item.classList.add('line-' + lineIndex);
            });
            const [
                line1,
                line2,
                line3,
                line4,
                line5,
                line6,
                line7,
                line8,
                line9,
                line10,
            ] = [
                titleRef.current.querySelectorAll('.line-1'),
                titleRef.current.querySelectorAll('.line-2'),
                titleRef.current.querySelectorAll('.line-3'),
                titleRef.current.querySelectorAll('.line-4'),
                titleRef.current.querySelectorAll('.line-5'),
                titleRef.current.querySelectorAll('.line-6'),
                titleRef.current.querySelectorAll('.line-7'),
                titleRef.current.querySelectorAll('.line-8'),
                titleRef.current.querySelectorAll('.line-9'),
                titleRef.current.querySelectorAll('.line-10'),
            ];
            const intervalValue = 200;
            let iteration = 1;
            let timeout = setInterval(() => {
                if (iteration <= linesArr.length) {
                    switch (iteration) {
                        case 1:
                            line1.forEach(item => {
                                item.classList.add('_animated');
                            });
                            break;
                        case 2:
                            line2.forEach(item => {
                                item.classList.add('_animated');
                            });
                            break;
                        case 3:
                            line3.forEach(item => {
                                item.classList.add('_animated');
                            });
                            break;
                        case 4:
                            line4.forEach(item => {
                                item.classList.add('_animated');
                            });
                            break;
                        case 5:
                            line5.forEach(item => {
                                item.classList.add('_animated');
                            });
                            break;
                        case 6:
                            line6.forEach(item => {
                                item.classList.add('_animated');
                            });
                            break;
                        case 7:
                            line7.forEach(item => {
                                item.classList.add('_animated');
                            });
                            break;
                        case 8:
                            line8.forEach(item => {
                                item.classList.add('_animated');
                            });
                            break;
                        case 9:
                            line9.forEach(item => {
                                item.classList.add('_animated');
                            });
                            break;
                        case 10:
                            line10.forEach(item => {
                                item.classList.add('_animated');
                            });
                            break;
                    }
                }
                iteration++;
            }, intervalValue);
            setTimeout(() => {
                buttonRef.current.classList.add('_animated');
            }, (linesArr.length + 1) * intervalValue);
            setTimeout(() => {
                clearInterval(timeout);
            }, 5000);
        }
    }, [titleArr]);

    return (
        <>
            <Helmet>
                <title>{home.title}</title>
                <meta property="og:title" content={`${home.title}`} />
                <meta property="twitter:title" content={`${home.title}`} />
                <meta name="description" content={`${home.description}`} />
                <meta
                    property="og:description"
                    content={`${home.description}`}
                />
                <meta
                    property="twitter:description"
                    content={`${home.description}`}
                />
                <meta
                    property="og:image"
                    content={`${siteDomain}/img/overbest_og_en.png`}
                />
                <meta property="og:url" content={`${siteDomain}/`} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            <main className="page">
                <section className="first-screen page__first-screen">
                    <MainAnimation />
                    <div className="first-screen__container first-screen__section-1">
                        <h1
                            className="first-screen__title title title--white"
                            ref={titleRef}
                        >
                            {titleArr &&
                                titleArr.length > 0 &&
                                titleArr.map((item, index) => (
                                    <span
                                        key={index}
                                        className="scroll-span-top"
                                        dangerouslySetInnerHTML={{
                                            __html: item + ' ',
                                        }}
                                    ></span>
                                ))}
                        </h1>
                        <a
                            href={home.button.Link}
                            className="first-screen__link button"
                            ref={buttonRef}
                        >
                            {home.button.Title}
                        </a>
                    </div>
                    <div className="first-screen__container first-screen__section-2">
                        <p className="first-screen__text smallTitle smallTitle--white">
                            {descArr &&
                                descArr.length > 0 &&
                                descArr.map((item, index) => (
                                    <span
                                        key={index}
                                        className="scroll-span"
                                        dangerouslySetInnerHTML={{
                                            __html: item + ' ',
                                        }}
                                    ></span>
                                ))}
                        </p>
                    </div>
                </section>
                <section className="our page__our">
                    <div className="our__container">
                        <div className="our__col">
                            <h2 className="our__title title title--black">
                                Our focus
                            </h2>
                            {home.ourFocus.length > 0 && (
                                <ul className="our__list">
                                    {home.ourFocus.map(item => (
                                        <li
                                            key={item.key}
                                            className="our__item"
                                        >
                                            <h3
                                                className="our__name smallTitle smallTitle--black"
                                                dangerouslySetInnerHTML={{
                                                    __html: item.title,
                                                }}
                                            ></h3>
                                            <p
                                                className="our__text text text--black"
                                                dangerouslySetInnerHTML={{
                                                    __html: item.description,
                                                }}
                                            ></p>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className="our__col">
                            <div className="our__wrap">
                                <h2 className="our__title title title--black">
                                    {home.ourFocusTitle}
                                </h2>
                                <a
                                    href={home.ourFocusButton.Link}
                                    className="our__btn button button--white"
                                >
                                    {home.ourFocusButton.Title}
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                
                {/* <section className="staff page__staff" ref={staffRef}> */}
                    {/* {staffRef && <MainWhoWeAreAnimation staffRef={staffRef} />}
                    <div className="staff__container staff__container--1">
                        <h2 className="staff__title title title--white">
                            {home.whoWeAre.Title}
                        </h2>
                        <p className="staff__text text text--white">
                            {home.whoWeAre.Description}
                        </p>
                        <a
                            href={home.whoWeAre.LinkUrl}
                            className="staff__btn button button-white"
                        >
                            {home.whoWeAre.LinkCaption}
                        </a>
                    </div> */}
                    {/* <div
                        className={`staff__container staff__container--2`}
                    >
                        {home.whoWeAreList.length > 0 && (
                            <ul className={`staff__list count--${home.whoWeAreList.length}`}>
                                {home.whoWeAreList.map(item => (
                                    <li key={item.key} className="staff__item">
                                        {item.photo && (
                                            <div className="staff__item-img">
                                                <img
                                                    src={`${domain}${item.photo}`}
                                                    alt={item.name}
                                                />
                                            </div>
                                        )}
                                        {!item.photo && (
                                            <div className="staff__item-img empty"></div>
                                        )}
                                        <h3 className="staff__item-title smallTitle smallTitle--white">
                                            {item.name}
                                        </h3>
                                        <span className="staff__item-position text text--white">
                                            {item.position}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        )}
                        <a href={home.whoWeAre.LinkUrl} className="staff__btn button button-white">{home.whoWeAre.LinkCaption}</a>
                    </div> */}
                {/* </section> */}
                
                <section className="positions page__positions">
                    <div className="positions__container">
                        <h2 className="positions__title title title--black">
                            {home.openPositions.Title}
                        </h2>
                        <p className="positions__text text text--black">
                            {home.openPositions.Description}
                        </p>
                        <a
                            href={home.openPositions.LinkUrl}
                            className="positions__btn button button--white"
                        >
                            {home.openPositions.LinkCaption}
                        </a>
                        {home.openPositionsList.length > 0 && (
                            <ul className="positions-list positions__list">
                                {home.openPositionsList.map(item => (
                                    <li
                                        key={item.key}
                                        className="positions-list__item"
                                    >
                                        <a href={item.link}>
                                            <h3 className="positions-list__name smallTitle smallTitle--black">
                                                {item.title}
                                            </h3>
                                            <span className="positions-list__location smallTitle">
                                                {item.location}
                                            </span>
                                            <span className="positions-list__btn button button--white button--medium">
                                                {item.labelLink}
                                            </span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </section>
            </main>
        </>
    );
};

export default HomeEn;
