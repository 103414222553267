import React, { useEffect, useState, useRef } from 'react';

export function CareersAnimation() {

    const animation = useRef();
    const [ rotation, setRotation ] = useState(140);
    //const [ bottom, setBottom ] = useState(60);
    const [ opacity, setOpacity ] = useState(0);
    const [ top, setTop ] = useState(0);

    const handleScroll = () => {
        const footerTop = window.document.querySelector("footer").getBoundingClientRect().top;
        const windowHeight = window.screen.height;
        let scroll = window.pageYOffset;
        let curOpacity = 1;
        let additionalBot = 0;
        if (footerTop - windowHeight < 0) {
            curOpacity = 1 + (footerTop - windowHeight)/700;
            additionalBot = (footerTop - windowHeight)/40;
            scroll += footerTop - windowHeight;
        } else if (footerTop - windowHeight < -200) {
            scroll += footerTop - windowHeight - 200/windowHeight;
        }

        let rotValue = 180 + scroll/60 - additionalBot;
        //let botValue = 60 - scroll/6 + additionalBot;
        setRotation(rotValue);
        //setBottom(botValue);
        setOpacity(curOpacity);
        setTop(scroll/50);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setRotation(180);
            setOpacity(1);
        }, 100);
        setTimeout(() => {
            animation.current.classList.add("_active");
        }, 200);
    }, []);

    return (
        <img
            className="animated"
            src="img/animations/careers-1.png"
            id="careers-animation"
            ref={animation}
            style={
                {
                    "transform":"translate(-65%,-85%) rotate(" + rotation + "deg)",
                    "top": top + "px",
                    "opacity": opacity,
                }
            }
        />
    );
}
